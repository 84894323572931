<template>
  <div class="container">
    <van-row type="flex" justify="center">
      <van-col span="24">
        <div style="text-align: center; margin-top: 25px; margin-bottom: 10px">
          <van-image :src="logo" />
        </div>
      </van-col>
    </van-row>
    <van-row type="flex" justify="center">
      <van-col span="24">
        <div style="text-align: center; height: 50px">中酿KM系统</div>
      </van-col>
    </van-row>

    <van-cell-group>
      <van-field
        @blur="checkuserName"
        label="用户名"
        size="large"
        v-model="loginForm.userName"
        placeholder="请输个人手机号或ZN开头的员工编号"
        :error-message="errMes.userName"
      ></van-field>
      <van-field
        @blur="checkpassWord"
        label="密码"
        size="large"
        v-model="loginForm.passWord"
        placeholder="默认小写:znpz"
        type="password"
        :error-message="errMes.passWord"
      >
        <template #button>
          <van-button size="small" @click="toFindPassword">忘记密码</van-button>
        </template>
      </van-field>

      <van-field name="switch" label="记住密码">
        <template #input>
          <van-switch v-model="loginForm.switchRemember" size="20" />
        </template>
      </van-field>
    </van-cell-group>
    <div class="btn-box">
      <van-button type="info" size="large" round block @click="login"
        >登录</van-button
      >
    </div>
    <div class="btn-box" v-if="showWX">
      <div v-if="isBand" style="text-align: center;">您正在微信中使用KM,即将自动登录...</div>
      <div v-if="!isBand" style="text-align: center;">您正在微信中使用KM,本次登录将和您的微信号绑定，下次将自动登录</div>
      <!-- <van-button type="info" size="large" round block @click="ClickLoginByWX">
        <div style="display: table-cell; vertical-align: middle">
          <van-icon name="chat-o" size="35"></van-icon>
          <div
            style="display: inline-block; margin-top: 8px; position: absolute"
          >
            使用微信登录
          </div>
        </div>
      </van-button> -->
    </div>
  </div>
</template>

<script>
//import Vue from 'vue';
import { Toast } from "vant";
import { mapMutations, mapGetters } from "vuex";
import { login, LoginByWX, GetWeixinOpenId } from "@/api/user";
import { GetAppId } from "@/api/wxuser";
import logo from "@/assets/logo.png";
import { setCookie } from "@/utils/cookie";
import { getAuth as getGrafanaAuth } from "@/api/keshihua";

export default {
  name: "login",
  data() {
    return {
      showWX: false,
      isBand:false,
      appid: "",
      code: "",
      logo: logo,
      loginForm: {
        switchRemember: true,
        userName: "",
        passWord: "",
        code: "",
        openId:"",
      },
      errMes: {
        userName: "",
        passWord: "",
      },

      isWeixin:
        navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1,
      isMiniprogram: false,
    };
  },
  activated() {
    this.initData();
    this.CheckWenXin();
  },
  created() {
    this.initData();
    this.CheckWenXin();
  },

  methods: {
    toFindPassword() {
      if (this.loginForm.userName.length == 11) {
        this.$router.push({
          path: "/user/findpassword",
          query: { username: this.loginForm.userName },
        });
      } else {
        this.$router.push({
          path: "/user/findpassword",
        });
      }
    },
    async initData() {
      console.log("initData");
      if (this.$route.query.username) {
        this.loginForm.userName = this.$route.query.username;
      }
      //有token直接做登录
      // if (this.$route.query.wxminiOpenid) {
      //   //登录
      //   const data = await LoginByWXmini(this.$route.query.wxminiOpenid);
      //   if (data.data.success) {
      //     //this.appid = data.data.data;
      //     this.updateUser({ user: data.data.data });
      //     Toast("微信小程序自动登录成功");
      //     this.code = "";
      //     // let { redirectUrl } = this.$route.query;
      //     // this.$router.push(redirectUrl || "/");
      //     //this.$router.push("/");

      //     let { redirectUrl } = this.$route.query;
      //     if (redirectUrl == undefined || redirectUrl.indexOf("login") > 0) {
      //       window.location.replace(window.location.origin);
      //     } else {
      //       window.location.replace(
      //         window.location.origin + "/#" + (redirectUrl || "/")
      //       );
      //     }

      //     //window.location.replace(window.location.origin);
      //     // window.location = window.location.origin;
      //     // window.location.reload(true);
      //   } else {
      //     Toast(data.data.errorMessage);
      //   }
      // }
      // //在微信小程序中调转
      // else if (this.isWeixin) {
      //   try {
      //     //var jsConfig=await GetAppJsConfig(encodeURIComponent(location.href.split("#")[0]));

      //     let win = window;
      //     if (win.wx) {
      //       // win.wx.config({
      //       //   debug: false,
      //       //   appId: jsConfig.appId,
      //       //   timestamp: jsConfig.timestamp,
      //       //   nonceStr: jsConfig.nonceStr,
      //       //   signature: jsConfig.signature,
      //       //   jsApiList: [
      //       //     "checkJsApi",

      //       //   ],
      //       // });

      //       win.wx.miniProgram.getEnv((res) => {
      //         console.log(res);
      //         if (res.miniprogram) {
      //           console.log("在小程序里！！！");
      //           win.wx.miniProgram.navigateTo({
      //             url: "/pages/znpzapp/znpzapp?clearLogin=true",
      //           });
      //         } else {
      //           console.log("不在小程序！！！");
      //         }
      //       });
      //     }
      //   } catch (error) {
      //     console.log(error);
      //   }
      // } else {
      //   console.log("不在微信中");
      // }

      let loginuser = this.getRememberUser();

      if (loginuser && loginuser != null) {
        this.loginForm.userName = loginuser.username;
        this.loginForm.passWord = loginuser.password;
      }

      //this.code = this.$route.query.code;
      var queryString = location.search;
      // Further parsing:
      let params = new URLSearchParams(queryString);
      this.code = params.get("code");
    },
    isWenXin: function () {
      if (navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1) {
        return true; // 是微信端
      } else {
        return false;
      }
    },
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    },

    async CheckWenXin() {
      this.showWX = false;

      if (this.isWenXin()) {
        if (this.code == null || this.code == "") {
          await this.GetAppId();
          if (
            this.appid != "" &&
            this.appid != undefined &&
            this.appid != null
          ) {
            window.location.href =
              "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
              this.appid +
              "&redirect_uri=" +
              encodeURIComponent(window.location.href) +
              "&response_type=code&scope=snsapi_base#wechat_redirect";
          }

          //跳转到微信获取用户code

          //this.$router.push("/login?code=123code123");
          
        } else if (this.code != undefined) {
          const opendata=  await GetWeixinOpenId(this.code);
          if (opendata.data.success) {
              this.loginForm.openId=opendata.data.data.openId;
              this.isBand=opendata.data.data.isBand
              this.showWX = true;
              if(this.isBand){
                  this.ClickLoginByWX();
              }

          }
          else{
            this.loginForm.openId="";
          }
        }
      }
    },

    async ClickLoginByWX() {
      //微信登录
      this.showWX = true;
      try {
        const para = {code:  this.loginForm.openId };

        const data = await LoginByWX({ ...para });
        if (data.data.success) {
          //this.appid = data.data.data;
          this.updateUser({ user: data.data.data });
          Toast("微信自动登录成功");
          this.code = "";
          // let { redirectUrl } = this.$route.query;
          // this.$router.push(redirectUrl || "/");
          //this.$router.push("/");

          let { redirectUrl } = this.$route.query;
          if (redirectUrl == undefined || redirectUrl.indexOf("login") > 0) {
            window.location.replace(window.location.origin);
          } else {
            window.location.replace(
              window.location.origin + "/#" + (redirectUrl || "/")
            );
          }

          //window.location.replace(window.location.origin);
          // window.location = window.location.origin;
          // window.location.reload(true);
        } else {
          Toast(data.data.errorMessage);
        }
      } catch (error) {
        Toast("异常" + error);
      }
    },

    async GetAppId() {
      try {
        const data = await GetAppId();
        if (data.data.success) {
          this.appid = data.data.data;
        } else {
          Toast(data.data.errorMessage);
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkuserName() {
      if (!this.loginForm.userName) {
        this.errMes.userName = "用户名不能为空";
        return false;
      }
      // if (!/^1[3-9]\d{9}$/.test(this.loginForm.userName)) {
      //   this.errMes.userName = '手机格式不正确'
      //   return false
      // }
      this.errMes.userName = "";
      return true;
    },
    checkpassWord() {
      if (!this.loginForm.passWord) {
        this.errMes.passWord = "密码不能为空";
        return false;
      }
      // if (!/^\d{6}$/.test(this.loginForm.passWord)) {
      //   this.errMes.passWord = '验证码为6位'
      //   return false
      // }
      this.errMes.passWord = "";
      return true;
    },
    // 登陆方法
    async login() {
      if (this.checkuserName() && this.checkpassWord()) {
        const data = await login(this.loginForm);
        if (data.data.success) {
          this.updateUser({ user: data.data.data });
          if (this.loginForm.switchRemember) {
            this.rememberUser({
              username: this.loginForm.userName,
              password: this.loginForm.passWord,
            });
          } else {
            this.rememberUser({
              username: this.loginForm.userName,
              password: "",
            });
          }

          let grafanaAuthData = await getGrafanaAuth();
          if (grafanaAuthData.data.success && grafanaAuthData.data.data) {
            setCookie("grafanaAuth", grafanaAuthData.data.data, 30);
          }
          var matchs = window.location.href.match(/(\/grafana.+?)#\/login/i);
          console.log(matchs);
          if (matchs && matchs.length > 1) {
            window.location.href = matchs[1];
          } else {
            let { redirectUrl } = this.$route.query;
            if (redirectUrl == undefined || redirectUrl.indexOf("login") > 0) {
              window.location.replace(window.location.origin);
            } else {
              window.location.replace(
                window.location.origin + "/#" + (redirectUrl || "/")
              );
            }
          }

          if (
            data.data.scoreMessage !== undefined &&
            data.data.scoreMessage != ""
          ) {
            Toast({
              message: data.data.scoreMessage,
              icon: "/image/signin_point_add.png",
            });
          } else {
            Toast("登录成功");
          }
        } else {
          Toast(data.data.errorMessage);
        }
      }
    },
    ...mapMutations(["updateUser", "rememberUser"]),

    ...mapGetters(["getRememberUser"]),
  },

  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query != from.query) {
        this.initData();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.btn-box {
  padding: 20px;
}
</style>
